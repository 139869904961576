@use "sass:math";

.card {
  position: relative;
  display: block;
  width: 100%;
  min-width: 280px;
  max-width: calc(#{$CANVAS_WIDTH} / #{$CANVAS_HEIGHT} * 100vh);
  height: 100%;
  margin: 0 auto;
  background-position: 50% 75%;
  background-repeat: no-repeat;
  background-size: cover;
  //background-image: linear-gradient(216deg, #634D94 40%, #27225F 100%);
  //background-image: url("./images/bg")
  z-index: 0;

  //&::before {
  //  content: "";
  //  position: absolute;
  //  top: 0;
  //  left: 0;
  //  width: 100%;
  //  height: height(1237, $CANVAS_HEIGHT);
  //  background-image: url("./images/snow-top.png");
  //  background-repeat: no-repeat;
  //  background-position: 50% 0;
  //  background-size: cover;
  //  z-index: -1;
  //}

  //&::after {
  //  content: "";
  //  position: absolute;
  //  bottom: 0;
  //  left: 0;
  //  width: 100%;
  //  height: height(732, $CANVAS_HEIGHT);
  //  background-image: url("./images/snow-bot.png");
  //  background-repeat: no-repeat;
  //  background-position: 50% 0;
  //  background-size: cover;
  //  z-index: -1;
  //}

  &__logo {
    position: absolute;
    top: height(60);
    left: width(35);
    display: block;
    width: width(310);
    height: height(150);
    background-image: url("./images/logo.png");
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: contain;
  }

  //&__button {
  //  position: absolute;
  //  top: height(62);
  //  right: width(34);
  //  display: block;
  //  width: 45px;
  //  height: 45px;
  //  border: none;
  //  border-radius: 50%;
  //  background-image: linear-gradient(180deg, #967DCF 0%, #876FBE 29%, #6C5896 100%);
  //  box-shadow: 0 3px 0 0 #413061;
  //  -webkit-touch-callout: none;
  //  -webkit-tap-highlight-color: transparent;
  //
  //  &::before {
  //    content: "";
  //    position: absolute;
  //    top: 0;
  //    left: 0;
  //    right: 0;
  //    bottom: 0;
  //    display: block;
  //    background-image: url("images/sound-on.svg");
  //    background-position: 50% 50%;
  //    background-repeat: no-repeat;
  //    background-size: contain;
  //    opacity: 1;
  //  }
  //
  //  &::after {
  //    content: "";
  //    position: absolute;
  //    top: 0;
  //    left: 0;
  //    right: 0;
  //    bottom: 0;
  //    display: block;
  //    background-image: url("images/sound-off.svg");
  //    background-position: 50% 50%;
  //    background-repeat: no-repeat;
  //    background-size: contain;
  //    opacity: 0;
  //  }
  //
  //  &:active {
  //    background-image: linear-gradient(180deg, #432F70 0%, #5B438F 47%, #6A4CAA 99%);
  //    box-shadow: 0 2px 0 0 #413061;
  //  }
  //
  //  &[aria-pressed="true"] {
  //    &::before {
  //      opacity: 0;
  //    }
  //
  //    &::after {
  //      opacity: 1;
  //    }
  //  }
  //}

  //&__image {
  //  position: absolute;
  //  top: 50%;
  //  left: 50%;
  //  display: block;
  //  width: 100%;
  //  height: height(1312);
  //  transform: translate(-50%, -50%);
  //  background-image: url("images/image.png");
  //  background-position: 50% 50%;
  //  background-repeat: no-repeat;
  //  background-size: contain;
  //}

  &__text {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    width: width(650);
    height: height(220);
    margin: 0 auto;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;

    &_ru {
      background-image: url("images/text-ru.png");
    }

    &_ge {
      background-image: url("images/text-ge.png");
    }

    &_kz {
      background-image: url("images/text-kz.png");
    }

    &_az {
      background-image: url("images/text-az.png");
    }

    &_uz {
      background-image: url("images/text-uz.png");
    }

    &_mn {
      background-image: url("images/text-mn.png");
    }

    //&_kg {
    //  background-image: url("images/text-kg.png");
    //}

    &_am {
      background-image: url("images/text-am.png");
    }

    &_by {
      background-image: url("images/text-ru.png");
    }
  }

  //&__popup {
  //  position: absolute;
  //  top: 0;
  //  left: 0;
  //  right: 0;
  //  bottom: 0;
  //  background-color: transparentize(#1B083E, .7);
  //
  //  &[hidden] {
  //    display: none;
  //  }
  //}

  //&__alert {
  //  position: absolute;
  //  top: 50%;
  //  left: 50%;
  //  display: block;
  //  width: width(799);
  //  height: height(526);
  //  transform: translate(-50%, -50%);
  //  background-image: url("images/alert.png");
  //  background-position: 50% 50%;
  //  background-repeat: no-repeat;
  //  background-size: contain;
  //}

  //&__ok {
  //  position: absolute;
  //  left: width(228, 799);
  //  bottom: height(40, 526);
  //  width: width(343, 799);
  //  height: height(143, 526);
  //  padding: 0;
  //  border: none;
  //  background-color: transparent;
  //
  //  &::before {
  //    content: "";
  //    position: absolute;
  //    top: 0;
  //    left: 0;
  //    width: 100%;
  //    height: 100%;
  //    background-image: url("images/btn-ok-normal.svg");
  //    background-position: 50% 50%;
  //    background-repeat: no-repeat;
  //    background-size: contain;
  //    opacity: 1;
  //  }
  //
  //  &::after {
  //    content: "";
  //    position: absolute;
  //    top: 0;
  //    left: 0;
  //    width: 100%;
  //    height: 100%;
  //    background-image: url("images/btn-ok-active.svg");
  //    background-position: 50% 50%;
  //    background-repeat: no-repeat;
  //    background-size: contain;
  //    opacity: 0;
  //  }
  //
  //  &:active {
  //    &::before {
  //      opacity: 0;
  //    }
  //
  //    &::after {
  //      opacity: 1;
  //    }
  //  }
  //}
}
